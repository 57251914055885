<template>
  <b-row
    class="notfound-row vh-75 d-flex flex-column align-items-center justify-content-center mx-0"
  >
    <b-col cols="12" sm="10" md="8" xl="6">
      <h-card>
        <b-row class="my-5 justify-content-center">
          <b-col cols="6" md="5" lg="5" xl="4">
            <b-img
              :src="require('@/assets/svg/myHubcast-logo.svg')"
              alt="radioshop logo"
              class="w-100"
            >
            </b-img>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <b-col cols="11" md="10" lg="8" xl="6">
            <b-img
              :src="require('@/assets/svg/illu-404.svg')"
              alt="radioshop logo"
              class="w-100"
            >
            </b-img>
          </b-col>
        </b-row>
        <div class="text-center mt-5">
          <h3>Cette page n'existe pas.</h3>
          <h-button
            @click="$router.go(-1)"
            blue
            class="mx-auto my-5 no-white-space d-flex align-items-center"
          >
            <b-icon icon="arrow-left" class="w-15px mr-2" font-scale="1">
            </b-icon>
            Retour
          </h-button>
        </div>
      </h-card>
    </b-col>
  </b-row>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.notfound-row {
  margin-top: 5%;
}
</style>
